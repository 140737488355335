import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import styles from './CheckBox.module.scss';

const CheckBoxGroup = ({ selectedValues, initialValue = [], onChange, items = [], type, labelId }) => {
  const [selectedItems, setSelectedItems] = useState(selectedValues ?? initialValue ?? []);

  useEffect(() => {
    setSelectedItems(selectedValues);
  }, [selectedValues]);
  return (
    <div className={styles['checkbox-group']} role={type === 'radio' ? 'radiogroup' : 'group'} aria-labelledby={labelId}>
      {items.map((item) => (
        <Checkbox
          key={item.value}
          label={item.display}
          type={type}
          value={item.value}
          checked={selectedItems.some((i) => i.value == item.value)}
          onChange={(e) => {
            const selectedValue = e.target.value;

            if (type === 'radio') {
              const tempSelectedItems = items.find((item) => item.value == selectedValue);
              setSelectedItems([tempSelectedItems]);
              onChange(tempSelectedItems);
            } else {
              const tempSelectedItems = e.target.checked
                ? [...selectedItems, items.find((item) => item.value == selectedValue)]
                : selectedItems.filter((item) => item.value != selectedValue);
              setSelectedItems(tempSelectedItems);
              onChange(tempSelectedItems);
            }
          }}
        />
      ))}
    </div>
  );
};
CheckBoxGroup.propTypes = {
  selectedValues: PropTypes.array.isRequired,
  initialValue: PropTypes.array,
  options: PropTypes.object,
  items: PropTypes.array,
  onChange: PropTypes.func,
  type: PropTypes.string,
  labelId: PropTypes.string,
};

export { CheckBoxGroup as default, CheckBoxGroup };
